<template>
  <page-header :title="title" v-model="searchParams" :breadcrumbs="pathMatch">
    <template v-slot:searchbar>
      <el-form :inline="true" size="mini">
        <AreaField v-model="searchParams.area_code" @change="getStationList" />
        <el-form-item>
          <el-select
            v-model="searchParams.site_id"
            filterable
            :clearable="true"
            placeholder="加油站（可输入）"
          >
            <el-option
              v-for="item in gasData"
              :key="item.id"
              :value="item.id"
              :label="item.name"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-search"
            size="mini"
            @click="handleQuery"
          >
            查询
          </el-button>
          <el-button
            type="primary"
            icon="el-icon-arrow-up"
            size="mini"
            @click="handleExport"
          >
            导出
          </el-button>
        </el-form-item>
      </el-form>
    </template>

    <default-table
      :columns="columns"
      :dataSource="data"
      :pagination="pagination"
      @currentChange="handleCurrentChange"
      :loading="tableLoading"
    >
      <template #index="{ scope }">{{ scope.$index + 1 }}</template>
      <template #remark="{ scope }">
        <span :class="scope.row.remark == '' ? 'text-danger' : 'text-success'">
          {{ scope.row.remark === '' ? '未处理' : scope.row.remark }}
        </span>
      </template>
      <template #actions="{ scope }">
        <el-button
          size="mini"
          type="primary"
          v-if="scope.row.remark === ''"
          @click="handleAction(scope.row, 'deal')"
        >
          处理
        </el-button>
        <el-button
          size="mini"
          type="primary"
          v-if="scope.row.remark !== ''"
          @click="handleAction(scope.row, 'view')"
        >
          查看
        </el-button>
      </template>
    </default-table>

    <el-dialog
      :visible.sync="visible"
      :close-on-click-modal="false"
      width="630px"
      custom-class="dialog"
      title="提交处理结果"
    >
      <ele-form
        v-model="formData"
        :form-desc="formDesc"
        labelPosition="right"
        :span="24"
        :isShowSubmitBtn="false"
        :isShowBackBtn="false"
      >
        <div style="text-align: center" v-show="!check" slot="form-footer">
          <el-button type="primary" size="mini" @click="handleSubmit">
            提交
          </el-button>
          <el-button type="info" plain size="mini" @click="visible = false">
            取消
          </el-button>
        </div>
      </ele-form>
    </el-dialog>
  </page-header>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import DefaultTable from '@/components/DefaultTable/new';
import { QueryEncoderList, ExportEncoder, saveChangeCode } from '@/api/alarms';
import EleForm from 'vue-ele-form';
import mixins from '@/mixins';

export default {
  components: {
    PageHeader,
    DefaultTable,
    EleForm
  },
  data() {
    return {
      tableLoading: true,
      check: true, //判断查看状态还是处理状态
      visible: false,
      detailInfo: [],
      current: 1,
      formData: {
        id: '',
        remark: ''
      },
      searchParams: {
        site_id: ''
      },
      rowRemark: '',
      columns: [
        {
          title: '序号',
          key: 'index',
          width: 64,
          scopedSlots: { customRender: 'index' }
        },
        {
          title: '加油站名称',
          key: 'site_name',
          minWidth: 100
        },
        {
          title: '加油机名称',
          key: 'tanker_name',
          minWidth: 100
        },
        {
          title: '设备编号',
          key: 'port'
          // width: 120
        },
        {
          title: '原编码器号',
          key: 'old_code',
          minWidth: 100
          // width: 140
        },
        {
          title: '新编码器号',
          key: 'new_code',
          minWidth: 100
          // width: 140
        },
        {
          title: '变更时间',
          key: 'time'
        },
        {
          title: '状态',
          key: 'remark',
          extraType: 'ntext',
          extra: ['未处理', '已处理'],
          scopedSlots: { customRender: 'remark' }
        },
        {
          title: '操作',
          key: 'actions',
          width: 80,
          // extra: [
          //   { label: '处理', name: 'deal', toggle: true },
          //   { label: '查看', name: 'view', toggle: true }
          // ],
          scopedSlots: { customRender: 'actions' }
        }
      ],
      data: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10
      },
      formDesc: {
        site_name: {
          type: 'input',
          label: '加油站名称',
          disabled: true
        },
        tanker_name: {
          type: 'input',
          label: '加油机名称',
          disabled: true
        },
        remark: {
          type: 'textarea',
          label: '备注信息'
        }
      }
    };
  },
  mixins: [mixins],
  created() {
    this.pathMatch = this.$router.history.current.matched;
    this.handleQuery({
      current: 1,
      pageSize: 10
    });
  },
  methods: {
    handleAction(e, t) {
      this.rowRemark = e.remark;
      switch (t) {
        case 'deal':
          this.check = false;
          this.handleSaveChange(e);
          break;
        case 'view':
          this.check = true;
          this.handleView(e);
          break;
      }
    },
    handleView(e) {
      this.visible = !this.visible;
      this.detailInfo = [
        {
          key: '加油站名称',
          value: e.site_name
        },
        {
          key: '加油机名称',
          value: e.tanker_name
        }
      ];
      this.formDesc = {
        site_name: {
          type: 'input',
          label: '加油站名称',
          disabled: true,
          default: e.site_name
        },
        tanker_name: {
          type: 'input',
          label: '加油机名称',
          disabled: true,
          default: e.tanker_name
        },
        remark: {
          type: 'textarea',
          label: '备注信息',
          disabled: true
        }
      };

      this.formData = {
        id: e.id,
        remark: e.remark
      };
    },
    handleSaveChange(e) {
      this.visible = !this.visible;
      this.detailInfo = [
        {
          key: '加油站名称',
          value: e.site_name
        },
        {
          key: '加油机名称',
          value: e.tanker_name
        }
      ];

      this.formDesc = {
        site_name: {
          type: 'input',
          label: '加油站名称',
          disabled: true,
          default: e.site_name
        },
        tanker_name: {
          type: 'input',
          label: '加油机名称',
          disabled: true,
          default: e.tanker_name
        },
        remark: {
          type: 'textarea',
          label: '备注信息'
        }
      };

      this.formData = {
        id: e.id,
        remark: e.remark
      };
    },
    handleSubmit() {
      const data = {
        ...this.formData,
        remark: this.formData.remark || '无'
      };
      saveChangeCode(data).then(res => {
        console.log(res);
        const { code, msg } = res;
        this.visible = false;
        if (code === 0) {
          this.$message({ message: '提交成功', type: 'success' });
        } else {
          this.$message({
            message: msg,
            type: 'error'
          });
        }
        this.handleQuery({
          current: this.current,
          pageSize: 10
        });
      });
    },
    handleQuery({ current, pageSize }) {
      this.pagination.current = current || 1;
      const params = {
        page: current || 1,
        limit: pageSize || 10,
        ...this.searchParams
      };
      QueryEncoderList(params).then(res => {
        // console.log(res);
        this.data = res.data;
        if (res.code === 0) {
          this.pagination = {
            ...this.pagination,
            total: res.count
          };
        } else {
          this.pagination = {
            ...this.pagination,
            total: 0
          };
        }
        this.tableLoading = false;
      });
    },
    handleCurrentChange(current) {
      this.handleQuery({
        current,
        pageSize: 10
      });
    },
    handleExport() {
      const params = {
        ...this.searchParams
      };

      ExportEncoder(params)
        .then(res => {
          // const { code, msg } = res;
          // if (code !== 0) {
          //   this.$message({
          //     message: msg || '导出失败',
          //     type: 'error'
          //   });
          // }
          this.$ExportFile(res, this.title);
        })
        .catch(err => {
          this.$message({
            message: err,
            type: 'error'
          });
        });
    },
    handleDeal() {
      const params = {
        remark: this.formData.remark || '无',
        id: this.formData.id
      };
      saveChangeCode(params).then(res => {
        console.log(res);
      });
    }
  }
};
</script>

<style lang="scss" scoped></style>
